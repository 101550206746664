import React, { useEffect, useState } from "react";
import {
    Skeleton,
    VStack,
    Select,
    Card,
    CardBody,
    HStack,
    Input,
    FormControl,
    FormLabel,
    Button,
    Checkbox
} from '@chakra-ui/react'
import axios from 'axios';
import WeeklyWordleView from "./WeeklyWordleView";
import DailyWordleView from "./DailyWordleView";

export default function Wordle({ user }) {
    const getDefaultQuery = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        const currentDay = today.getDay();
        const diff = today.getDate() - currentDay;
        const sunday = new Date(today.setDate(diff));

        const dds = String(sunday.getDate()).padStart(2, '0');
        const mms = String(sunday.getMonth() + 1).padStart(2, '0');
        const yyyys = sunday.getFullYear();

        return { startDate: `${yyyys}-${mms}-${dds}`, endDate: `${yyyy}-${mm}-${dd}`, view: "Daily" };
    }
    
    const [results, setResults] = useState([]);
    const [weeklyResults, setWeeklyResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState(getDefaultQuery());
    const [filter, setFilter] = useState({justMisses: false})

    useEffect(() => {
        setLoading(true);
        const init = async () => {
            await getResults();
        }

        init();
    }, [query.view]);

    useEffect(() => {
        setLoading(false);
    }, [results, weeklyResults])

    const handleViewChanged = (view) => {
        if (view === "Weekly") {
            const today = new Date();
            const currentDay = today.getDay();
            const diff = today.getDate() - currentDay - 21;
            const sunday = new Date(today.setDate(diff));

            const dds = String(sunday.getDate()).padStart(2, '0');
            const mms = String(sunday.getMonth() + 1).padStart(2, '0');
            const yyyys = sunday.getFullYear();

            setQuery({ ...query, startDate: `${yyyys}-${mms}-${dds}`, view: view });
        } else {
            setQuery(getDefaultQuery());
        }
    }

    const getResults = async () => {
        //const userGroups = await axios.get(`/api/data/userGroups?email=${user.email}`);
        if (query.view === "Weekly") {
            const response = await axios.get(`/api/data/weeklyResults?email=${user.email}&startDate=${query.startDate}`);
            setWeeklyResults(response.data);
        } else {
            const response = await axios.get(`/api/data/dailyResults?email=${user.email}&startDate=${query.startDate}&endDate=${query.endDate}&justMisses=${filter.justMisses}`);
            setResults(response.data);
        }
    }

    return (
        <VStack justifyContent={"center"}>
            <Card>
                <Skeleton isLoaded={!loading} startColor='blue.500' endColor='purple.500'>
                    <CardBody>
                        {
                            <React.Fragment>
                                <VStack justifyContent={"center"} gap={1} marginBottom={"10px"}>
                                    <Select value={query.view} onChange={(e) => { handleViewChanged(e.target.value); }} color={"white"} bgColor="#565656">
                                        <option value='Daily'>Daily</option>
                                        <option value='Weekly'>Weekly</option>
                                    </Select>
                                    <HStack justifyContent={"center"} alignItems={"end"} gap={1}>
                                        <FormControl>
                                            <FormLabel>From:</FormLabel>
                                            <Input minWidth={"190px"} type={"date"} value={query.startDate} onChange={(e) => { setQuery({ ...query, startDate: e.target.value }); }} />
                                        </FormControl>
                                        {
                                            query.view === "Daily" ? 
                                            <FormControl>
                                                <FormLabel>To:</FormLabel>
                                                <Input minWidth={"190px"} type={"date"} value={query.endDate} onChange={(e) => { setQuery({ ...query, endDate: e.target.value }); }} />
                                            </FormControl>
                                            : null
                                        }
                                    </HStack>
                                    <HStack justifyContent={"center"} alignItems={"end"} gap={3}>
                                        <Button colorScheme={"blue"}  onClick={async () => { await getResults(); }}>Search</Button>
                                        <FormControl>
                                            <HStack alignItems={"center"}>
                                                <FormLabel>Just My Incomplete:</FormLabel>
                                                <Checkbox isChecked={filter.justMisses} onChange={(e) => { setFilter({...filter, justMisses: e.target.checked})}} />
                                            </HStack>
                                        </FormControl>
                                    </HStack>
                                </VStack>
                                {
                                    query.view !== "Weekly" ?
                                        <DailyWordleView user={user} results={results} onCorrectionCompleted={getResults} /> :
                                        <WeeklyWordleView weeklyResults={weeklyResults} />
                                }
                            </React.Fragment>
                        }
                    </CardBody>
                </Skeleton>
            </Card>
        </VStack>
    )
}