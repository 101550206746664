import React, { useEffect, useState } from 'react';
import { ChakraProvider, HStack, Skeleton, Text } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem, IconButton, Image } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import Login from './login';
import Wordle from './Wordle/Wordle';
import Everdell from './Everdell/Everdell';
import LandGrab from './Landgrab/Landgrab';
import Quests from './Quest/Quest'

const Main = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialLoadCompleted, setInitialLoadingCompleted] = useState(false);
  const [viewingState, setViewingState] = useState("Wordle");

  useEffect(() => {
    const check = async (user) => {
      const response = await axios.get(`/api/data/login?email=${user.email}`);
      refreshCookie(response.data);
    }

    if (cookies.user) {
      check(cookies.user);
    } else {
      setLoggedIn(false);
      setInitialLoadingCompleted(true);
    }

    setLoading(false);
  }, []);

  const refreshCookie = (user) => {
    const today = new Date();
    const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);

    if (user.tab === undefined) {
      user.tab = "Wordle";
    }

    setCookie("user", user, { expires: nextweek });
    setLoggedIn(true);
    setInitialLoadingCompleted(true);
  }

  const handleUserLoggedIn = (user) => {
    refreshCookie(user);
  };

  return (
    <ChakraProvider>
      <CookiesProvider>
        <div id="main" style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#343434" }}>
          {
            <Skeleton isLoaded={initialLoadCompleted && !loading} startColor='blue.500' endColor='purple.500'>
              {
                cookies.user && cookies.user.isValid && loggedIn ?
                  <React.Fragment>
                    <HStack justifyContent={"center"} marginBottom={1}>
                      <IconButton className={(viewingState === "Wordle" ? "blue" : "black")} onClick={() => { setViewingState("Wordle"); }} variant={"outline"} icon={<Image h={"30px"} w={"30px"} src="../img/wordle-icon.png" />} />
                      {
                        cookies.user.userId < 7 ?
                          <React.Fragment>
                            <IconButton className={(viewingState === "Everdell" ? "blue" : "black")} onClick={() => { setViewingState("Everdell"); }} variant={"outline"} icon={<Image h={"30px"} w={"30px"} src="../img/everdell-icon.png" />} />
                            <IconButton className={(viewingState === "Landgrab" ? "blue" : "black")} onClick={() => { setViewingState("Landgrab"); }} variant={"outline"} icon={<Image h={"30px"} w={"30px"} src="../img/landgrab-icon.png" />} />
                          </React.Fragment> : null
                      }
                      {
                        cookies.user.userId === 1 ||
                          cookies.user.userId === 2 ||
                          cookies.user.userId === 12 ?
                          <IconButton className={(viewingState === "Quest" ? "blue" : "black")} onClick={() => { setViewingState("Quest"); }} variant={"outline"} icon={<Image h={"30px"} w={"30px"} src="../img/quest-icon.png" />} />
                          : null
                      }                      
                    </HStack>
                    {
                      viewingState === "Wordle" ? <Wordle user={cookies.user} /> 
                      : viewingState ===  "Everdell" ? <Everdell user={cookies.user} /> 
                      : viewingState ===  "Landgrab" ? <LandGrab  />
                      : viewingState === "Quest" ? <Quests user={cookies.user} />
                      : null 
                    }                    
                  </React.Fragment>
                  :
                  <Login onLogin={handleUserLoggedIn} />
              }
            </Skeleton>
          }
        </div>
      </CookiesProvider>
    </ChakraProvider>
  );
};

export default Main;