import React, { useEffect, useState } from "react";
import {
    Skeleton,
    VStack,
    Text,
    Button,
    HStack,
    Input,
    Checkbox,
    Textarea,
} from '@chakra-ui/react'
import axios from 'axios';

export default function Quests({ user }) {
    const initNewQuest = {
        questId: 0,
        name: "",
        description: "",
        reward: "",
        isActive: true,
        lastCompleted: null,
        questData: "",
        jsonData: {
            participants: [],
            rewardGiver: ""
        }
    }
    const [loading, setLoading] = useState(true);
    const [quests, setQuests] = useState([])
    const [newQuest, setNewQuest] = useState(initNewQuest)
    const [itsBen, setItsBen] = useState(false)

    useEffect(() => {
        const init = async () => {
            await getQuests()
            setLoading(false);
        }

        init();
    }, [])

    useEffect(() => {
        setItsBen(user.userId === 12)
    },[user])

    const saveQuest = async (edited) => {
        await axios.post(`/api/data/quest`, edited);
        setNewQuest(initNewQuest)
        await getQuests();
    }

    const completeQuest = async (edited) => {
        edited.isActive = false
        await axios.post(`/api/data/quest`, edited)
        await getQuests();
        setNewQuest(initNewQuest)
    }

    const getQuests = async () => {
        const response = await axios.get(`/api/data/quest`);
        setQuests(response.data);
    }

    // 1 Paul, 2 Liz, 12 Ben
    return (
        <Skeleton isLoaded={!loading} startColor='blue.500' endColor='purple.500'>
            <VStack gap={2} width="95vw" alignItems={"center"} maxH={"90vh"} overflowY={"scroll"}>
                {
                    itsBen && !quests.some(q => q.isActive) ?
                    <div className="goldHeader questBoxDisplay">
                        There are no currently active quests!
                    </div> : null
                }
                {
                    itsBen ?
                        quests.map(q => 
                            q.isActive ?
                            <VStack key={q.questId} gap={1} w={350} className="questBoxDisplay" alignItems={"flex-start"}>
                                <HStack w={"100%"} justifyContent={"center"}>
                                    <Text className="goldHeader">
                                        {q.name}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text color="white">
                                        {q.description}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text className="goldHeader">
                                        Reward: {q.reward}
                                    </Text>
                                </HStack>
                                <HStack padding={"5px"} justifyContent={"center"} w={"100%"}>
                                    <Button onClick={async () => { await completeQuest(q) }}>
                                        Complete Quest
                                    </Button>
                                </HStack>
                            </VStack> : null
                        ) : 
                        quests.map(q =>                         
                            <VStack key={q.questId} gap={1} alignItems={"flex-start"} className="questBox">
                                <HStack >
                                    <Input color={'white'} w={350} placeholder="Name" value={q.name} onChange={(e) => { setQuests(quests.map(quest => (quest.questId === q.questId ? { ...q, name: e.target.value } : quest))) }} />
                                </HStack>
                                <HStack>
                                    <Textarea color={'white'} w={350} placeholder="Description" value={q.description} onChange={(e) => { setQuests(quests.map(quest => (quest.questId === q.questId ? { ...q, description: e.target.value } : quest))) }} />
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Input w={250} color={'white'} placeholder="Reward" value={q.reward} onChange={(e) => { setQuests(quests.map(quest => (quest.questId === q.questId ? { ...q, reward: e.target.value } : quest))) }} />
                                    <HStack>
                                        <Text color={"white"}>Active:</Text>
                                        <Checkbox isChecked={q.isActive} onChange={(e) => { setQuests(quests.map(quest => (quest.questId === q.questId ? { ...q, isActive: e.target.checked } : quest))) }} />
                                    </HStack>
                                </HStack>
                                <HStack padding={"5px"} justifyContent={"flex-end"} w={"100%"}>
                                    <Button onClick={async () => { await saveQuest(q) }}>
                                        Save
                                    </Button>
                                </HStack>
                            </VStack>
                        )
                }
                {
                    !itsBen ?
                        <VStack gap={1} alignItems={"flex-start"} className="questBox">
                            <HStack>
                                <Input color={'white'} w={350} placeholder="Name" value={newQuest.name} onChange={(e) => { setNewQuest({ ...newQuest, name: e.target.value }) }} />
                            </HStack>
                            <HStack>
                                <Textarea color={'white'} w={350} placeholder="Description" value={newQuest.description} onChange={(e) => { setNewQuest({ ...newQuest, description: e.target.value }) }} />
                            </HStack>
                            <HStack justifyContent={"space-between"}>
                                <Input w={350} color={'white'} placeholder="Reward" value={newQuest.reward} onChange={(e) => { setNewQuest({ ...newQuest, reward: e.target.value }) }} />
                            </HStack>
                            <HStack padding={"5px"} justifyContent={"flex-end"} w={"100%"}>
                                <Button onClick={async () => { await saveQuest(newQuest) }}>
                                    Save
                                </Button>
                            </HStack>
                        </VStack> : null
                }
            </VStack>
        </Skeleton>
    )
}