import React, { useEffect, useState } from "react";
import {
    Skeleton,
    VStack,
    NumberInput,
    NumberInputField,
    Select,
    Table,
    Box,
    TableCaption,
    TableContainer,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Text,
    Td,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Button,
    HStack,
    Input,
} from '@chakra-ui/react'
import {
    CheckIcon,
    ArrowLeftIcon
} from '@chakra-ui/icons';
import axios from 'axios';
import { BarChart, Bar, Cell, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function LandGrab() {
    const fillColors = ["darkred", "darkblue", "darkgreen", "purple"];

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const init = async () => {
            const response = await axios.get(`/api/data/landgrab`);
            setUsers(response.data);
            setLoading(false);
        }

        if (users.length === 0) init();
    }, []);

    const updateUserScore = async (userId) => {
        const newScore = await axios.post(`/api/data/landgrab?userId=${userId}&score=1`);
        setUsers(users.map(u => u.userId == userId ? ({ ...u, score: newScore.data }) : ({...u})));
    }

    return (
        <Skeleton isLoaded={!loading} startColor='blue.500' endColor='purple.500'>
            <Box height={"600px"} width={"400px"} backgroundColor={"#efefef"}>
                <ResponsiveContainer width={"100%"} height={"100%"}>
                    <BarChart
                        data={users}
                        margin={{
                            top: 25,
                            right: 25,
                            left: 0,
                            bottom: 25,
                        }}>
                        <XAxis dataKey="user" />
                        <YAxis type="number" domain={[0, Math.max(...users.map(o => o.score))]} />
                        <Tooltip />
                        <Legend content={<Text fontWeight={"bold"}>Victories Since 12/23</Text>} />
                        <Bar
                            key={"userId"} 
                            dataKey={"score"}
                            name={"Score"}
                            fill={"darkblue"}
                        />
                    </BarChart>
                </ResponsiveContainer>
                
            </Box>
        </Skeleton>
    )
}