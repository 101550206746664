import React, { useEffect, useState } from "react";
import {
    Skeleton,
    VStack,
    NumberInput,
    NumberInputField,
    Select,
    Table,
    Box,
    TableCaption,
    TableContainer,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Text,
    Td,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Button,
    HStack,
    Input,
} from '@chakra-ui/react'
import {
    CheckIcon,
    ArrowLeftIcon
} from '@chakra-ui/icons';
import axios from 'axios';
import { BarChart, Bar, Cell, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function Everdell({ user }) {
    const init = [
        {
            name: 'Paul',
            userId: 1,
            score: 0
        },
        {
            name: 'Liz',
            userId: 2,
            score: 0
        },
        {
            name: 'Mike',
            userId: 3,
            score: 0
        },
        {
            name: 'Lev',
            userId: 4,
            score: 0
        },
        {
            name: 'Chris',
            userId: 5,
            score: 0
        }
    ]

    const [loading, setLoading] = useState(true);
    const [gameData, setGameData] = useState([]);
    const [allBars, setAllBars] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [everdellScores, setEverdellScores] = useState(init);
    const [everdellScoreDate, setEverdellScoreDate] = useState("");
    const fillColors = ["darkred", "darkblue", "darkgreen", "purple"];

    const handleEverdellSubmission = async () => {
        const scoreData = everdellScores.filter(e => e.score && e.score > 0).map(e => ({score: e.score, userId: e.userId}));
        const data = {
            gameDate: everdellScoreDate,
            userScores: scoreData
        }
        await axios.post(`/api/data/everdell`, data);
        setEverdellScores(init);
    }

    const updateUserScore = (user, value) => {
        const updated = everdellScores.map(e => {
            if (e.userId === user.userId) {
                return { ...e, score: value }
            }
            return e;
        })
        setEverdellScores(updated);
    }

    useEffect(() => {
        const init = async () => {
            const response = await axios.get(`/api/data/game?gameType=Everdell`);
            const gameData = response.data.map(r => {
                let retVal = {};
                retVal.date = r.gameDateStr;
                r.gameScores.forEach(gs => {
                    retVal[gs.user.firstName] = gs.score;
                });
                return retVal;
            })

            setGameData(gameData);
            setLoading(false);
        }

        if (gameData.length === 0) init();
    }, [])

    const viewGameDetail = (game) => {
        let bars = [];
        for (var prop in game)
            if (prop !== "date")
                bars.push(prop);

        setSelectedGame([game]);
        setAllBars([...bars]);
    }

    const getCradleName = (str, num) => {
        let numStr = " - Copper"
        if (num < 30) numStr = " - Iron"
        else if (num < 35) numStr = " - Jade"
        else if (num < 40) numStr = " - Low Gold"
        else if (num < 45) numStr = " - High Gold"
        else if (num < 50) numStr = " - True Gold"
        else if (num < 55) numStr = " - Underlord"
        else if (num < 60) numStr = " - Overlord"
        else if (num < 65) numStr = " - Archlord"
        else if (num < 75) numStr = " - Sage"
        else if (num < 80) numStr = " - Herald"
        else if (num < 90) numStr = " - Monarch"
        else if (num >= 90) numStr = " - Dreadgod"

        return str + numStr;
    }

    return (
        <Skeleton isLoaded={!loading} startColor='blue.500' endColor='purple.500'>
            <Box overflowY={"auto"} maxHeight={"75vh"} backgroundColor={"#efefef"}>
                <TableContainer overflowX={"scroll"} maxWidth={"95vw"}>
                    <Table size='sm' variant='striped'>
                        <Tbody>
                            {
                                gameData.map(g => {
                                    let arr = Object.values(g);
                                    arr.shift();
                                    const winner = Math.max(...arr);
                                    return (
                                        <Tr key={g.date} onClick={() => { viewGameDetail(g); }}>
                                            {
                                                Object.keys(g).map(p => {
                                                    return <Td key={p}>
                                                        {
                                                            p === "date" ? g.date :
                                                                <VStack
                                                                    justifyContent={"center"}
                                                                    textColor={g[p] === winner ? "green" : null}
                                                                    fontWeight={g[p] === winner ? "bold" : null}>
                                                                    <Text>{g[p]}</Text>
                                                                    <Text>{p}</Text>
                                                                </VStack>
                                                        }
                                                    </Td>
                                                }
                                                )
                                            }
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
                {
                    selectedGame !== null ?
                        <Modal isOpen={true} onClose={() => { setSelectedGame(null); }}>
                            <ModalOverlay />
                            <ModalContent w={"100vw"} h={"75vh"}>
                                <ModalHeader>{selectedGame[0].date}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody w={"100%"} h={"100%"}>
                                    <ResponsiveContainer width={"100%"} height={"100%"}>
                                        <BarChart
                                            data={selectedGame}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}>
                                            <XAxis dataKey="date" />
                                            <YAxis type="number" domain={[0, 100]} />
                                            <Tooltip />
                                            <Legend />
                                            {
                                                allBars.map((s, i) =>
                                                    <Bar key={s} dataKey={`${s}`} name={getCradleName(s, selectedGame[0][s])} fill={fillColors[i]} />
                                                )
                                            }
                                        </BarChart>
                                    </ResponsiveContainer>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                        : null
                }
            </Box>
            {
                user.userId === 1 ?
                    <VStack gap={1} justifyContent={"center"}>
                        <HStack gap={2} justifyContent={"center"} padding={1} w={400}>
                            {
                                everdellScores.map((u) => {
                                    return (
                                        <VStack align={"center"} key={u.userId} >
                                            <Text color={"white"}>{u.name}</Text>
                                            <NumberInput value={u.score} color={"white"} onChange={(e) => { updateUserScore(u, e); }}>
                                                <NumberInputField />
                                            </NumberInput>
                                        </VStack>
                                    )
                                })
                            }
                        </HStack>
                        <HStack justifyContent={"space-between"} alignItems={"end"}>
                            <VStack align={"center"}>
                                <Text color={"white"}>Game Date</Text>
                                <Input type={"date"} color={"white"} value={everdellScoreDate} onChange={(e) => { setEverdellScoreDate(e.target.value); }} />
                            </VStack>
                            <Button onClick={async () => { await handleEverdellSubmission(); }}>Submit</Button>
                        </HStack>
                    </VStack>
                    : null
            }
        </Skeleton>)
}