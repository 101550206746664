import React, {useState} from "react";
import { Input, Button, VStack, Skeleton } from '@chakra-ui/react'
import axios from 'axios';

export default function Login({onLogin}) {
    const [email, setEmail] = useState("");    
    const [loading, setLoading] = useState(false);

    const onLoggedIn = async () => {
        setLoading(true);
        const response = await axios.get(`/api/data/login?email=${email}`);
        if(response.data && response.data.isValid) {
            onLogin(response.data);
        }
        setLoading(false);
    };

    return (        
        <Skeleton isLoaded={!loading} startColor='blue.500' endColor='purple.500'>
            <VStack justifyContent={"center"}>
                <Input color={"white"} value={email} onChange={(e) => { setEmail(e.target.value);}} placeholder="Who are you?" />
                <Button onClick={onLoggedIn}>Login</Button>
            </VStack>
        </Skeleton>        
    );
}

