import React, { useEffect, useState } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import {
    Skeleton,
    VStack,
    Select,
    Table,
    Text,
    Box,
    TableCaption,
    TableContainer,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    ModalContent,
    ModalOverlay,
    Button,
    HStack,
} from '@chakra-ui/react'
import {
    CheckIcon,
    ArrowLeftIcon
} from '@chakra-ui/icons';
import axios from 'axios';
import { dictionary } from './wordDict';

export default function DailyView({ user, results, onCorrectionCompleted }) {
    const [loading, setLoading] = useState(true);
    const [makeupWord, setMakeupWord] = useState(null);
    const [isMakingUp, setIsMakingUp] = useState(false);
    const [makeupScore, setMakeupScore] = useState(null);
    const [colDefs, setColDefs] = useState([]);
    const [widthModifier, setWidthModifier] = useState(1);

    const autoSizeStrategy = {
        type: 'fitCellContents',
        defaultMinWidth: 85,
        columnLimits: [
            {
                colId: 'ScoreDate',
                minWidth: 120
            }
        ]
    };

    const scoreCellRenderer = props => {

        if(!props.data) {
            if (props.column.colId === "ScoreDate")
                return (
                    <div style={{ color: "black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        Totals:
                    </div>
                )
            else {
                const total = results.map(r => r[props.column.colId]).reduce((p,n) => p + n);
                return (
                    <div style={{ color: "black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {total}
                    </div>
                )
            }
        } else {
            const scoreData = props.data[`${props.colDef.field}_MetaData`];
            return (
                scoreData === undefined ?
                    <div style={{ color: "black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {props.value}
                    </div>
                    :
                    <div
                        onClick={(scoreData.userId == user.userId && (scoreData.canViewResults || scoreData.wasMissed) ? async () => { await handleMakeupClicked(props.data.ScoreDate, scoreData.userId); } : null)}
                        style={{
                            color: scoreData.wasMissed ? "red" : "black",
                            fontWeight: scoreData.wasMissed ? "bold" : "initial",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: (scoreData.canViewResults || scoreData.wasMissed) && scoreData.userId == user.userId ? "pointer" : "initial"
                        }}>
                        {props.value}
                    </div>
            )
        }
    }

    useEffect(() => {
        setLoading(false);
        const firstResult = results.length && results.length > 0 ? results[0] : null;
        if (firstResult !== null) {
            const objProps = Object.getOwnPropertyNames(firstResult).filter(pr => !pr.includes("MetaData"));
            setColDefs(objProps.map(p => ({
                field: p,
                cellRenderer: scoreCellRenderer
            })))
            setWidthModifier(objProps.length - 1);
        }
    }, [results, isMakingUp])

    const handleMakeupClicked = async (date, userId) => {
        const response = await axios.get(`/api/data/word?date=${date}`);
        if (response.data !== null && response.data !== "") {
            const guessResponse = await axios.post(`/api/data/getWordleScore?userId=${userId}&date=${date}`);
            setMakeupScore(guessResponse.data);
            setMakeupWord(response.data);
            setIsMakingUp(true);
        } else {
            alert("Word for today is not yet available.  Give it an hour, or use the Wordle page.");
        }
    }

    const makeupScoreSubmitted = async () => {
        onCorrectionCompleted();
        setIsMakingUp(false);
        setMakeupScore(null);
    }

    return (
        <Skeleton isLoaded={!loading} startColor='blue.500' endColor='purple.500'>
            <Box overflowY={"auto"} maxHeight={"50vh"} maxWidth={"90vw"}>
                {
                    colDefs.length && colDefs.length > 0 ?
                        <div className="ag-theme-quartz" style={{ width: `${(widthModifier * 50 + 250)}px`, height: results.length && results.length <= 7 ? "initial" : "75vh" }}>
                            <AgGridReact
                                autoSizeStrategy={autoSizeStrategy}
                                rowData={results}
                                columnDefs={colDefs}
                                domLayout={results.length && results.length <= 7 ? "autoHeight" : null}
                                grandTotalRow={"bottom"}
                                suppressColumnVirtualisation={true}
                            />
                        </div>
                        : null
                }
            </Box>
            {
                isMakingUp ?
                    <MakeupResult
                        result={makeupScore}
                        onSubmit={makeupScoreSubmitted}
                        targetWord={makeupWord}
                        user={user}
                    /> : null
            }
        </Skeleton>)
}

function MakeupResult({ user, result, onSubmit, targetWord }) {
    const [score, setScore] = useState(0);
    const [isMakingUp, setIsMakingUp] = useState(true);
    const [guess, setGuess] = useState({
        currGuess: "",
        currGuessNum: 1,
        isSuccessful: false
    });
    const [attemptedLetters, setAttemptedLetters] = useState([]);
    const [correctLetters, setCorrectLetters] = useState([]);
    const [priorGuesses, setPriorGuesses] = useState([]);
    const onClose = () => {
        setScore(0);
        onSubmit();
    }
    const [successfulStatus, setSuccessfulStatus] = useState(null);

    useEffect(() => {
        setAttemptedLetters([...new Set(priorGuesses.map(t => t.currGuess.split("")).flat())]);
    }, [priorGuesses]);

    useEffect(() => {
        if (result.guessData && result.guessData.guesses.length) {
            const savedPriorGuesses = [...result.guessData.guesses];
            setPriorGuesses([...result.guessData.guesses]);
            setGuess({ currGuessNum: parseInt(savedPriorGuesses.slice(-1)[0].currGuessNum) + 1, currGuess: "", isSuccessful: false });

            if (result.guessData.guessIsComplete) {
                setSuccessfulStatus({
                    guessNum: result.guessData.guesses.filter(g => g.isSuccessful)[0].currGuessNum,
                    date: result.scoreDateStr,
                    word: targetWord
                });
            }
        }
    }, [result]);

    useEffect(() => {
        if (priorGuesses.length > 0) {
            const lastGuess = priorGuesses.slice(-1)[0];

            const updatedGuess = {
                scoreType: "Wordle",
                scoreValue: lastGuess.isSuccessful ? lastGuess.currGuessNum : null,
                scoreDate: result.scoreDateStr,
                guessData: {
                    guesses: [...priorGuesses]
                }
            }

            const postScore = async (guess) => {
                if (user) {
                    const response = await axios.post(`/api/data/post?email=${user.email}&guessData=${JSON.stringify(guess)}`);

                    if (!response.data.error) {
                        if (lastGuess.isSuccessful) {
                            setSuccessfulStatus({
                                guessNum: lastGuess.currGuessNum,
                                date: result.scoreDateStr,
                                word: targetWord
                            });
                        }
                    }
                }
            }

            postScore(updatedGuess);
        }
    }, [priorGuesses]);

    const postMakeup = async (score) => {
        const updatedGuess = {
            scoreType: "Wordle",
            scoreValue: score,
            scoreDate: result.scoreDateStr
        }

        const postScore = async (guess) => {
            if (user) {
                const response = await axios.post(`/api/data/postPrior?email=${user.email}&guessData=${JSON.stringify(guess)}`);

                if (!response.data.error) {
                    alert(`Success!`);
                    onSubmit();
                }
            }
        }
        postScore(updatedGuess);
    }

    const rowOne = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
    const rowTwo = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
    const rowThree = ["ENTER", "Z", "X", "C", "V", "B", "N", "M", "BACK"];
    const rowCount = [1, 2, 3, 4, 5, 6];
    const letterCount = [1, 2, 3, 4, 5];

    const addLetter = (e) => {
        if (guess.currGuess.length <= 5) {
            setGuess({ ...guess, currGuess: guess.currGuess + e });
        }
    }

    const bumpLetter = () => {
        if (guess.currGuess.length <= 5) {
            setGuess({ ...guess, currGuess: guess.currGuess.substring(0, guess.currGuess.length - 1) });
        }
    }

    const attemptGuess = () => {
        if (guess.currGuess.length === 5 && guess.currGuessNum < 7) {
            if (guess.currGuess === targetWord) {
                setGuess({ ...guess, isSuccessful: true });
                setPriorGuesses([...priorGuesses, { currGuess: guess.currGuess, currGuessNum: guess.currGuessNum, isSuccessful: true }]);
            }
            else if (guess.currGuessNum === 6) {
                setGuess({ ...guess, currGuessNum: 7, isSuccessful: true });
                setPriorGuesses([...priorGuesses, { currGuess: guess.currGuess, currGuessNum: guess.currGuessNum, isSuccessful: true }]);
            }
            else if (dictionary.some(d => d === guess.currGuess.toLowerCase())) {
                let newCorrectLetters = [...correctLetters];
                for (let i = 0; i <= 4; i++) {
                    if (targetWord[i] === guess.currGuess[i] && !newCorrectLetters.includes(targetWord[i])) {
                        newCorrectLetters.push(targetWord[i]);
                    }
                }

                setCorrectLetters([...newCorrectLetters]);
                setPriorGuesses([...priorGuesses, { currGuess: guess.currGuess, currGuessNum: guess.currGuessNum, isSuccessful: false }]);
                setGuess({ ...guess, currGuess: "", currGuessNum: guess.currGuessNum + 1 });
            } else {
                alert("Not a word!");
            }
        }
    }

    const getGuessLetterClass = (targetLetterIndex, theGuessInvolved) => {
        if (theGuessInvolved === undefined) return "";

        const theLetterInvolved = theGuessInvolved.currGuess[targetLetterIndex];
        const regExInstances = new RegExp(theLetterInvolved, "g");
        const matches = targetWord.match(regExInstances || []);
        const instancesOfLetterInWord = matches === null ? 0 : (matches).length;

        let letterInstances = [];

        // First identify the easy cases.  Greens and Blacks.
        const letterMap = [...theGuessInvolved.currGuess].map((t, i) => {
            const isGreen = targetWord[i] === theGuessInvolved.currGuess[i];

            // Case #1:  Green, return
            if (isGreen) {
                letterInstances.push({ status: "green", letter: theGuessInvolved.currGuess[i] });
                return { status: "green", letter: t };
            } else { // Case #2: If you're not green, we'll say you're black for now.
                return { status: "", letter: t };
            }
        });

        // Now that we've established greens, lets setup yellows.
        letterMap.forEach((l, i) => {
            if (l.status !== "green") {
                // Get a count of highlights for the letter so we don't give false positive yellows
                let potentialYellowInstances = letterInstances.filter(l => (l.status === "green" || l.status === "yellow") && l.letter === theGuessInvolved.currGuess[i]).length;

                // If we're below the desired instances, this must be yellow since its not green.
                if (potentialYellowInstances < instancesOfLetterInWord) {
                    letterInstances.push({ status: "yellow", letter: theGuessInvolved.currGuess[i] });
                    l.status = "yellow";
                } else {
                    l.status = "";
                }
            }
        })

        return letterMap[targetLetterIndex].status;
    }

    const shareResult = async () => {
        const green = '🟩';
        const yellow = '🟨';
        const black = '⬛';

        let str = `${successfulStatus.date} - OC Wordle ${successfulStatus.guessNum}/6\n\n`;
        let allDone = false;

        const buttons = [...document.querySelectorAll(".guessButton")];
        const firstRow = buttons.filter((b, i) => i <= 4).map(e => e.classList.value.includes("green") ? "green" : e.classList.value.includes("yellow") ? "yellow" : "black");
        const secondRow = buttons.filter((b, i) => i > 4 && i <= 9).map(e => e.classList.value.includes("green") ? "green" : e.classList.value.includes("yellow") ? "yellow" : "black");
        const thirdRow = buttons.filter((b, i) => i > 9 && i <= 14).map(e => e.classList.value.includes("green") ? "green" : e.classList.value.includes("yellow") ? "yellow" : "black");
        const fourthRow = buttons.filter((b, i) => i > 14 && i <= 19).map(e => e.classList.value.includes("green") ? "green" : e.classList.value.includes("yellow") ? "yellow" : "black");
        const fifthRow = buttons.filter((b, i) => i > 19 && i <= 24).map(e => e.classList.value.includes("green") ? "green" : e.classList.value.includes("yellow") ? "yellow" : "black");
        const sixthRow = buttons.filter((b, i) => i > 24 && i <= 29).map(e => e.classList.value.includes("green") ? "green" : e.classList.value.includes("yellow") ? "yellow" : "black");

        firstRow.forEach(r => {
            str += r === "green" ? green : r === "yellow" ? yellow : black;
        })
        str += '\n';

        allDone = firstRow.every(r => r === "green");

        if (!allDone) {
            secondRow.forEach(r => {
                str += r === "green" ? green : r === "yellow" ? yellow : black;
            })
            str += '\n';
            allDone = secondRow.every(r => r === "green");
        }

        if (!allDone) {
            thirdRow.forEach(r => {
                str += r === "green" ? green : r === "yellow" ? yellow : black;
            })
            str += '\n';
            allDone = thirdRow.every(r => r === "green");
        }

        if (!allDone) {
            fourthRow.forEach(r => {
                str += r === "green" ? green : r === "yellow" ? yellow : black;
            })
            str += '\n';
            allDone = fourthRow.every(r => r === "green");
        }

        if (!allDone) {
            fifthRow.forEach(r => {
                str += r === "green" ? green : r === "yellow" ? yellow : black;
            })
            str += '\n';
            allDone = fifthRow.every(r => r === "green");
        }

        if (!allDone) {
            sixthRow.forEach(r => {
                str += r === "green" ? green : r === "yellow" ? yellow : black;
            })
            str += '\n';
        }

        str = str.substring(0, str.length - 1);
        await navigator.share({ title: `${successfulStatus.date} - OC Wordle`, text: str });
    }

    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                {
                    isMakingUp && targetWord && targetWord !== "" ?
                        <React.Fragment>
                            <ModalHeader>{result.guessData.guessIsComplete ? "Results" : "Making Up"}: {result.scoreDateStr}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <VStack alignContent={"center"} gap={3}>
                                    <VStack alignContent={"center"}>
                                        {
                                            rowCount.map(rowNum => {
                                                return (
                                                    <HStack justifyContent={"center"} key={rowNum}>
                                                        {
                                                            letterCount.map(letterNum => {
                                                                if (rowNum > guess.currGuessNum) {
                                                                    return <div key={letterNum} className="guessButton">{matchingLetter}</div>
                                                                }

                                                                let matchingLetter = "";
                                                                const matchingGuess = priorGuesses.find(g => g.currGuessNum == rowNum);
                                                                const letterIdx = letterNum - 1;

                                                                if (matchingGuess) {
                                                                    matchingLetter = matchingGuess.currGuess[letterIdx];
                                                                } else if (guess.currGuessNum == rowNum && guess.currGuess.length) {
                                                                    matchingLetter = guess.currGuess[letterIdx];
                                                                    return <div key={letterNum} className="guessButton">{matchingLetter}</div>
                                                                }

                                                                const classNameStr = `guessButton ${getGuessLetterClass(letterIdx, matchingGuess)}`;

                                                                return <div key={letterNum} className={classNameStr}>{matchingLetter}</div>
                                                            })
                                                        }
                                                    </HStack>
                                                )
                                            })
                                        }
                                    </VStack>
                                    {
                                        !result.guessData.guessIsComplete ?
                                            <VStack alignContent={"center"}>
                                                <HStack justifyContent={"center"}>
                                                    {
                                                        rowOne.map((r, idx) => {
                                                            const letterIsGuessed = attemptedLetters.some(p => p === r);
                                                            const letterIsCorrect = correctLetters.includes(r);
                                                            const letterExistsInWord = letterIsGuessed && targetWord.includes(r);
                                                            const classNameStr = `keyboardButton ${(letterIsCorrect ? "green" : letterExistsInWord ? "yellow" : letterIsGuessed ? "darkGrey" : "")}`;
                                                            return <div key={idx} className={classNameStr} onClick={() => { addLetter(r) }}>{r}</div>

                                                        })
                                                    }
                                                </HStack>
                                                <HStack justifyContent={"center"}>
                                                    {
                                                        rowTwo.map((r, idx) => {
                                                            const letterIsGuessed = attemptedLetters.some(p => p === r);
                                                            const letterIsCorrect = correctLetters.includes(r);
                                                            const letterExistsInWord = letterIsGuessed && targetWord.includes(r);
                                                            const classNameStr = `keyboardButton ${(letterIsCorrect ? "green" : letterExistsInWord ? "yellow" : letterIsGuessed ? "darkGrey" : "")}`;
                                                            return <div key={idx} className={classNameStr} onClick={() => { addLetter(r) }}>{r}</div>
                                                        })
                                                    }
                                                </HStack>
                                                <HStack justifyContent={"center"}>
                                                    {
                                                        rowThree.map((r, idx) => {
                                                            if (r === "ENTER") {
                                                                return <div key={idx} className="keyboardButton green" style={{ width: "60px" }} onClick={() => { attemptGuess() }}><CheckIcon /></div>
                                                            } else if (r == "BACK") {
                                                                return <div key={idx} className="keyboardButton grey" style={{ width: "60px" }} onClick={() => { bumpLetter() }}><ArrowLeftIcon /></div>
                                                            } else {
                                                                const letterIsGuessed = attemptedLetters.some(p => p === r);
                                                                const letterIsCorrect = correctLetters.includes(r);
                                                                const letterExistsInWord = letterIsGuessed && targetWord.includes(r);
                                                                const classNameStr = `keyboardButton ${(letterIsCorrect ? "green" : letterExistsInWord ? "yellow" : letterIsGuessed ? "darkGrey" : "")}`;
                                                                return <div key={idx} className={classNameStr} onClick={() => { addLetter(r) }}>{r}</div>
                                                            }
                                                        })
                                                    }
                                                </HStack>
                                            </VStack>
                                            : null
                                    }

                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                {
                                    successfulStatus === null ?
                                        <HStack justifyContent={"space-between"} width={"100%"}>
                                            <Button colorScheme="green" onClick={() => { setIsMakingUp(!isMakingUp); }}>
                                                Set Score
                                            </Button>
                                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                                Cancel
                                            </Button>
                                        </HStack> :
                                        <VStack alignItems={"center"} width={"100%"}>
                                            <Text fontWeight={"bold"}>
                                                {successfulStatus.word} in {successfulStatus.guessNum}!
                                            </Text>
                                            <Button colorScheme="green" onClick={async () => { await shareResult(); }}>
                                                Share Results
                                            </Button>
                                        </VStack>
                                }
                            </ModalFooter>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <ModalHeader>Set Score: {result.scoreDateStr}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Select value={score} onChange={(e) => { if (e.target.value) setScore(e.target.value); }} placeholder="Score..." color={"white"} bgColor="#565656">
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>X</option>
                                </Select>
                            </ModalBody>
                            <ModalFooter>
                                <HStack justifyContent={"space-between"} width={"100%"}>
                                    <Button colorScheme="green" onClick={() => { setIsMakingUp(!isMakingUp); }}>
                                        Make Up
                                    </Button>
                                    <HStack gap={1}>
                                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                                            Cancel
                                        </Button>
                                        {
                                            score > 0 ? <Button onClick={() => { postMakeup(score); }}>Submit</Button> : null
                                        }
                                    </HStack>
                                </HStack>
                            </ModalFooter>
                        </React.Fragment>
                }
            </ModalContent>
        </Modal>
    )
}