import React, { useEffect, useState } from "react";
import {
    Table,
    Box,
    TableCaption,
    TableContainer,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react'

export default function WeeklyWordleView({ weeklyResults }) {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        if (weeklyResults && weeklyResults.length) {
            setUserList([...new Set(weeklyResults.map(w => [...w.weeklyScores]).flat().map(w => w.user.firstName))]);
        }
    }, [weeklyResults]);

    return (
        <Box overflowY={"auto"} maxHeight={"75vh"}>
            <TableContainer overflowX={"scroll"} maxWidth={"95vw"}>
                <Table size='sm' variant='striped'>
                    <TableCaption placement="top" fontSize={"125%"}>
                        Weekly Results
                    </TableCaption>
                    <Thead>
                        <Tr>
                            <Th>&nbsp;</Th>
                            {
                                userList.map((x, i) =>
                                    <Th textAlign={"center"} key={i}>
                                        {x}
                                    </Th>
                                )
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            weeklyResults.map((result, i) =>
                                <Tr key={i}>
                                    <Td textAlign={"center"}>{result.weekBeginDate}</Td>
                                    {
                                        userList.map((u, j) => {
                                            const relevantScore = result.weeklyScores.find(s => s.user.firstName === u);
                                            return <Td
                                                textAlign={"center"}
                                                color={relevantScore.weekRank === 1 ? "green" : ""}
                                                fontWeight={relevantScore.weekRank === 1 ? "bold" : ""}
                                                key={j}>{relevantScore ? `${relevantScore.score} (#${relevantScore.weekRank})` : ""}
                                            </Td>
                                        })
                                    }
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}